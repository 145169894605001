import Heading from 'components/Heading/Heading';
import TextLink from 'components/TextLink/TextLink';
import { Sidebar } from 'types/siteAPI';

import styles from './LinkList.module.scss';

export default function LinkList({ content }: { content: Sidebar }) {
	const { settings } = content;

	return (
		<div>
			<Heading variant="h2">{settings.title}</Heading>
			<ul className={styles.LinkList}>
				{settings?.items.map((item) => {
					return (
						<li key={item.title}>
							<TextLink appearance="text" href={item.url}>
								{item.title}
							</TextLink>
						</li>
					);
				})}
			</ul>
		</div>
	);
}
